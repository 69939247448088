<template>
  <div>
    <label for="text" v-if="hasLabel" :style="getLabelStyles">{{label||data.label }}</label>
    <span v-if="data.validations.required" style="color:red;font-weight:bold;">
          *
        </span>
    <el-popover
      v-if="data.description"
      placement="top-start"
      trigger="hover"
      :content="data.description"
    >
      <i class="el-icon-info" slot="reference"></i>
    </el-popover>
    <el-select
      v-if="!isView && data.global_variable_type == 'USERS'"
      v-loading="loading"
      :style="getStyle"
      v-model="form[data.key]"
      :disabled="checkReadonly()"
    >
      <el-option
        v-for="(user, index) of users"
        :value="getValueForUserSelect(user)"
        :key="index"
        :label="getPrimaryDataForUser(user)"
      ></el-option>
    </el-select>

    <el-select
      v-if="
        !isView &&
        data.global_variable_type == 'ENTITY' &&
        checkSelectType('single') &&
        !isEntity
      "
      v-loading="loading"
      :style="getStyle"
      v-model="form[data.key]"
      @change="updateEntityFields"
      :disabled="checkReadonly()"
    >
      <el-option
        v-for="(entity, index) of entities"
        :value="entity._id"
        :key="index"
        :label="getPrimaryDataForEntity(entity)"
      ></el-option>
      <el-option
        v-if="data.allow_new_entity_data"
        value="addNewData"
        key="addNew"
        :label="`Add New ${data.label}`"
      >
      </el-option>
    </el-select>

    <el-select
      v-if="
        !isView &&
        data.global_variable_type == 'ENTITY' &&
        checkSelectType('multiple') &&
        !isEntity
      "
      v-loading="loading"
      :style="getStyle"
      v-model="form[data.key]"
      @change="updateEntityFieldsMultiple"
      multiple
      filterable
      :disabled="checkReadonly()"
      collapse-tags
    >
      <el-option
        v-for="(entity, index) of entities"
        :value="entity._id"
        :key="index"
        :label="getPrimaryDataForEntity(entity)"
      ></el-option>
      <el-option
        v-if="data.allow_new_entity_data"
        value="addNewData"
        key="addNew"
        :label="`Add New ${data.label}`"
      >
      </el-option>
    </el-select>

    <el-select
      v-if="!isView && data.global_variable_type == 'ENTITY' && isEntity"
      :multiple-limit="checkSelectType('single') ? 1 : 0"
      multiple
      v-loading="loading"
      :style="getStyle"
      v-model="form[data.key]"
      @change="updateEntityFieldsMultiple"
      filterable
      :disabled="checkReadonly()"
      collapse-tags
    >
      <el-option
        v-for="(entity, index) of entities"
        :value="entity._id"
        :key="index"
        :label="getPrimaryDataForEntity(entity)"
        :disabled="disableRelationshipEntityData(entity._id)"
      ></el-option>
      <el-option
        v-if="data.allow_new_entity_data"
        value="addNewData"
        key="addNew"
        :label="`Add New ${data.label}`"
      >
      </el-option>
    </el-select>
    <p v-if="isView" :style="getStyle" v-loading="loading">
      <template v-if="form[data.key]">
      {{
        getDataFromValue(form[data.key])
      }}
      </template>
      <template v-else>-</template>
    </p>

    <el-dialog
      :visible.sync="showAddNewEntityData"
      top="5%"
      width="600px"
      :title="`ADD NEW ${data.label.toUpperCase()}`"
    >
      <div
        class="px-20"
        v-loading="fetchingAddNewEntityDataEntity"
        v-if="
          addNewEntityDataEntity &&
          addNewEntityDataEntity.primary_fields &&
          addNewEntityDataEntity.primary_fields.length
        "
      >
        <div
          class="mb-20"
          v-for="(item, index) of addNewEntityDataEntity.primary_fields"
          v-bind:key="index"
        >
          <el-form>
            <div
              :label="item.label"
              v-if="item.input_type == 'SINGLE_LINE_TEXT' || item.input_type == 'PASSWORD' || item.input_type == 'EMAIL' || item.input_type == 'MASKED' || item.input_type == 'HYPER_LINK' "
            >
              <el-input
                type="number"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'EMAIL'"
            >
              <el-input
                type="email"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'PASSWORD'"
            >
              <el-input
                type="password"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'MASKED'"
            >
              <el-input
                type="text"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'HYPER_LINK'"
            >
              <el-input
                type="text"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'CHECKBOX'"
            >
              <el-input
                type="checkbox"
                :model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'RADIO'"
            >
              <el-input
                type="RADIO"
                :model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div :label="item.label" v-if="item.input_type == 'DATE'">
              <el-date-picker
                prefix-icon="el-icon-date"
                v-model="addEntityData[item.key]"
                type="date"
                :placeholder="item.label"
              ></el-date-picker>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'MULTI_LINE_TEXT'"
            >
              <el-input
                type="textarea"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="(item.input_type == 'NUMBER' || item.input_type == 'PHONE' || item.input_type == 'FAX' || item.input_type == 'CURRENCY' )"
            >
              <el-input
                type="number"
                :placeholder="item.label"
                v-model="addEntityData[item.key]"
              ></el-input>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'SELECT'"
            >
              <el-select
                :placeholder="item.placeholder"
                v-model="addEntityData[item.key]"
              >
                <el-option
                  v-for="(option, index) of item.options"
                  :value="option.value"
                  :key="index"
                  :label="option.name"
                ></el-option>
              </el-select>
            </div>
            <div
              :label="item.label"
              v-if="item.input_type == 'MULTI_SELECT'"
            >
              <el-select v-model="addEntityData[item.key]" multiple collapse-tags>
                <el-option
                  v-for="(option, index) of item.options"
                  :value="option"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </el-form>
        </div>
        <div class="text-right">
          <el-button type="success" @click="submitAddNewEntityData">
            Submit
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { bus } from "../../../main";

export default {
  name:"templates-formComponentsExecute-GlobalVariableExecute",
  mixins: [userPermissionsHelper],

  components: {},
  props: ["data","label", "form", "isView", "hasLabel", "fieldsData", "parentConfig", "isEntity", "entityRelationshipsData","colorFields"],
  computed: {
    ...mapGetters("users", ["getUsersList"]),

    ...mapGetters("repeatableTemplates", ["getParentEntityData"]),

    ...mapGetters("companyEntities", [
      "getAllCompanyEntitiesData",
      "getSingleCompanyEntity",
    ]),
    ...mapGetters("entitiesData", [
      "getAllEntitiesData",
      "getAllEntityRelationshipsData",
      "getAllEntitiesDataByIds",
      "getAllEntityRelationshipDataByIds",
      "getEntitiesDataAddStatus",
      "getNewlyAddedEntityData",
    ]),
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },

    getPrimaryDataForUser() {
      return (user) => {
        let primaryFields = user.user_type.allowed_fields.filter(
          (e) => e.primary
        );

        let data = [];

        primaryFields.forEach((field) => {
          data.push(user[field.key]);
        });
        data.length
        return data.join(" ");
      };
    },

    getPrimaryDataForEntity() {
      return (entity) => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach((field) => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });
        return data.join(" ");
      };
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      users: [],
      entities: [],
      loading: false,
      entityDataId: "",
      filledData: {},
      selectedMultipleIds: [],
      showAddNewEntityData: false,
      fetchingAddNewEntityDataEntity: false,
      addNewEntityDataEntity: {},
      addEntityData: {},
      maxLength:[]
    };
  },
  async mounted() {
    this.options = this.data.options || [];
    this.fetchUsers();
    await this.fetchEntities();
    bus.$on("fill-entity-data", (data) => {
      // if (this.data.is_relational && this.fetchEntitiesDataStatus(data.entity_id.relationships, this.data._id)) {
      let parentKey = data.parent_key;
      if (
        data.parent_key[0] == "#" &&
        this.data.global_variable_entity_parent &&
        this.data.global_variable_entity_parent[0] != "#"
      ) {
        parentKey = data.parent_key.substring(1, data.parent_key.length);
      }
      if (
        this.data.is_relational &&
        parentKey == this.data.global_variable_entity_parent
      ) {
        this.filledData = data;
        // if (this.form[this.data.key]) {
        //   this.form[this.data.key] = ""
        // }
        this.clearData(this.filledData);
        this.fetchEntitiesData(
          data._id,
          this.data.relation_entity_id,
          this.data._id,
          data.select_type,
          data.multiple_select_removed
        );
      }
    });
    bus.$on("clear-data", (data) => {
      if (
        this.data.is_relational &&
        data.parent_key == this.data.global_variable_entity_parent
      ) {
        this.clearData(this.data);
      }
    });

    if (this.form[this.data.key]) {
      this.$store.dispatch("repeatableTemplates/updateParentEntityData", {
        entity_id: this.data.global_variable_entity_type,
        data: this.form[this.data.key],
      });
    }
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      let userType = this.data.global_variable_user_type;

      let params = {
        user_type: userType,
        get_all: true,
      };

      await this.$store.dispatch("users/fetchAllUsers", params);
      this.users =
        this.getUsersList && this.getUsersList.data
          ? [...this.getUsersList.data]
          : [];
      this.loading = false;
    },
    getValueForUserSelect(user) {
      let value = "";
      if (user.first_name || user.last_name) {
        value += user.first_name + " " + user.last_name;
      } else {
        value += user.email;
      }

      return value;
    },
    async fetchEntities() {
      let formDataKey =
        this.data.key[0] == "#" ? this.data.key : "#" + this.data.key;
      if (
        !this.data.global_variable_entity_parent ||
        this.isView ||
        (this.data.global_variable_entity_select_type == "multiple"
          ? this.form[formDataKey] && this.form[formDataKey].length
          : this.form[formDataKey])
      ) {
        this.loading = true;
        let params = {
          get_all: true,
          entity_id: this.data.global_variable_entity_type,
          filters:
            this.data.filters && this.data.filters.length
              ? this.data.filters
              : [],
        };

        if (this.parentConfig) {
          if (
            this.getParentEntityData &&
            this.getParentEntityData[this.parentConfig.entity_id] && params.entity_id!=this.getParentEntityData[this.parentConfig.entity_id].entity_id
          ) {
            params.parent_config = this.getParentEntityData[
              this.parentConfig.entity_id
            ];
          }
          
        }
        await this.$store.dispatch("entitiesData/getEntitiesData", params);

        this.entities =
          this.getAllEntitiesData && this.getAllEntitiesData.data
            ? [...this.getAllEntitiesData.data]
            : [];
        this.loading = false;
        if(this.form && this.parentConfig){
          this.updateEntityFields(this.form[this.data.field_key]);
        }
        
      }
    },
    fetchEntitiesDataStatus(relationships, relationshipId) {
      let value = false;
      if (relationships && relationships.length) {
        relationships.forEach((element) => {
          if (element._id == relationshipId) {
            value = true;
          }
        });
      }
      return value;
    },
    async fetchEntitiesData(
      entityDataId,
      relationshipEntityId,
      relationshipId,
      selectType,
      multipleSelectRemoved
    ) {
      this.loading = true;
      await this.$store.dispatch(
        "entitiesData/fetchEntityRelationshipsDataByIds",
        {
          relationship_entity_id: relationshipEntityId,
          relationship_id: relationshipId,
        }
      );

      let details =
        this.getAllEntityRelationshipDataByIds &&
        this.getAllEntityRelationshipDataByIds.length
          ? [...this.getAllEntityRelationshipDataByIds]
          : [];
      if (details.length) {
        let relationshipData = details.find(
          (x) => x.entity_data_id == entityDataId
        );

        if (relationshipData) {
          await this.$store.dispatch("entitiesData/getEntitiesDataByIds", {
            entity_data_ids: this.getEntityDataIds(
              relationshipData.relationship_entity_data_ids
            ),
          });
          if (selectType == "multiple") {
            if (
              multipleSelectRemoved &&
              this.getAllEntitiesDataByIds &&
              this.getAllEntitiesDataByIds.data
            ) {
              let removeData = this.getAllEntitiesDataByIds.data;
              let result = this.entities.filter(function (objFromA) {
                return !removeData.find(function (objFromB) {
                  return objFromA._id === objFromB._id;
                });
              });
              this.entities = [...[], ...result];
            } else {
              this.entities =
                this.getAllEntitiesDataByIds &&
                this.getAllEntitiesDataByIds.data
                  ? [...this.entities, ...this.getAllEntitiesDataByIds.data]
                  : [];
            }
          } else {
            this.entities =
              this.getAllEntitiesDataByIds && this.getAllEntitiesDataByIds.data
                ? [...this.getAllEntitiesDataByIds.data]
                : [];
          }
          this.loading = false;
        } else {
          this.entities = [];
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    clearData(data) {
      if (this.form[this.data.key]) {
        this.form[this.data.key] = "";
        if (data) {
          bus.$emit("clear-data", this.data);
        }
      }
    },
    getEntityDataIds(data) {
      let ids = [];
      data.forEach((element) => {
        ids.push(element._id);
      });
      return ids;
    },
    checkReadonly() {
      //If it's an entity varaible, have to check access 
      if(this.data.field_assignable === 'read_only' && this.data.is_entity_variable){
        return true
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    getDataFromValue(globalVariableId) {
      if (this.data.global_variable_type == "USERS") {
        let user = this.users.find((user) => user._id == globalVariableId);
        if (user) {
          return this.getPrimaryDataForUser(user);
        }
        return "NA";
      } else {
        if (globalVariableId && Array.isArray(globalVariableId) ) {
          let value = ""
          globalVariableId.forEach(id => {
            let entity = this.entities.find(
              (entity) => entity._id == id
            );
            if (entity) {
              value = value + this.getPrimaryDataForEntity(entity) + ", ";
            }
          })
          return value ? value : "NA"
        } else {
          let entity = this.entities.find(
            (entity) => entity._id == globalVariableId
          );
          if (entity) {
            return this.getPrimaryDataForEntity(entity);
          }
          return "NA";
         }
      }
    },
    async updateEntityFields(data, removed = false) {
      if (data == "addNewData") {
        this.showAddNewEntityData = true;
        this.fetchingAddNewEntityDataEntity = true;
        this.form[this.data.key] = "";
        try {
          await this.$store.dispatch(
            "companyEntities/fetchSingleCompanyEntity",
            this.data.global_variable_entity_type
          );
          if (this.getSingleCompanyEntity) {
            this.addNewEntityDataEntity = this.getSingleCompanyEntity;
          }
          this.fetchingAddNewEntityDataEntity = false;
        } catch (error) {
          console.log("updateEntityFields",error);
        }
      } else {
        let selectedEntityData = this.entities.find((x) => x._id == data);
        //  this.form[this.data.key] = selectedEntityData.label
        selectedEntityData["parent_key"] =
          this.data && this.data.key ? this.data.key : "";
        selectedEntityData["select_type"] = this.data
          .global_variable_entity_select_type
          ? this.data.global_variable_entity_select_type
          : "single";
        selectedEntityData["multiple_select_removed"] = removed;
        bus.$emit("fill-entity-data", selectedEntityData);
        this.data.value = data;

        this.$store.dispatch("repeatableTemplates/updateParentEntityData", {
          entity_id: this.data.global_variable_entity_type,
          data: data,
        });
      }
    },
    async updateEntityFieldsMultiple(data) {
      if (data == "addNewData") {
        this.showAddNewEntityData = true;
        this.fetchingAddNewEntityDataEntity = true;
        this.form[this.data.key] = "";
        try {
          await this.$store.dispatch(
            "companyEntities/fetchSingleCompanyEntity",
            this.data.global_variable_entity_type
          );
          if (this.getSingleCompanyEntity) {
            this.addNewEntityDataEntity = this.getSingleCompanyEntity;
          }
          this.fetchingAddNewEntityDataEntity = false;
        } catch (error) {
          console.log("updateEntityFieldMultiple",error);
        }
      } else {
        if (this.selectedMultipleIds.length) {
          if (data.length > this.selectedMultipleIds.length) {
            this.updateEntityFields(data[data.length - 1]);
            this.selectedMultipleIds = [...[], ...data];
          }
          if (data.length < this.selectedMultipleIds.length) {
            let result = this.selectedMultipleIds.filter(
              (item) => data.indexOf(item) == -1
            );
            this.updateEntityFields(result[0], true);
            this.selectedMultipleIds = [...[], ...data];
          }
        } else {
          this.updateEntityFields(data[0]);
          this.selectedMultipleIds = [...[], ...data];
        }
        this.data.value = data;
        this.$store.dispatch("repeatableTemplates/setParentEntityData", {
          entity_id: this.data.global_variable_entity_type,
          data: data,
        });
      }
    },
    disableRelationshipEntityData(entityDataId, relationshipEntityId=this.data.global_variable_entity_type) {
      let value = false;
      if (
        this.entityRelationshipsData[relationshipEntityId] &&
        this.entityRelationshipsData[relationshipEntityId].length && (this.data.global_variable_entity_select_type == 'single' || this.data.global_variable_entity_select_type == 'multiple') && this.data.target_relationship_selection_type == 'single'
      ) {
        for (
          let index = 0;
          index < this.entityRelationshipsData[relationshipEntityId].length;
          index++
        ) {
          const element = this.entityRelationshipsData[relationshipEntityId][
            index
          ];
          // if (element.relationship_entity_data_ids.indexOf(entityDataId) > -1) {
          //   value = true;
          //   break;
          // }
          element.relationship_entity_data_ids.forEach((item) => {
            if (item._id == entityDataId) {
              value = true;
            }
          });
        }
      }
      return value;
    },
    checkSelectType(type) {
      if (this.data && this.data.global_variable_entity_select_type) {
        if (this.data.global_variable_entity_select_type == "single") {
          return type == "single";
        }

        if (this.data.global_variable_entity_select_type == "multiple") {
          return type == "multiple";
        }
      } else {
        return type == "single";
      }
    },
    async submitAddNewEntityData() {
      let params = {
        entity_id: this.data.global_variable_entity_type,
        entity_data: this.addEntityData,
      };
      this.fetchingAddNewEntityDataEntity = true;
      await this.$store.dispatch("entitiesData/addEntitiesData", params);

      if (this.getEntitiesDataAddStatus) {
        this.fetchingAddNewEntityDataEntity = false;
        this.showAddNewEntityData = false;
        this.$notify.success({
          title: "Success",
          message: "Entity Data Added",
        });

        if (this.getNewlyAddedEntityData) {
          this.getNewlyAddedEntityData[
            "entity_id"
          ] = this.addNewEntityDataEntity;
          this.entities.push(this.getNewlyAddedEntityData);
          this.addEntityData = {};
          if (this.data.global_variable_entity_select_type == "multiple") {
            if (this.form[this.data.key] && this.form[this.data.key].length) {
              this.form[this.data.key].push(this.getNewlyAddedEntityData._id);
            } else {
              this.form[this.data.key] = [];
              this.form[this.data.key].push(this.getNewlyAddedEntityData._id);
            }
          } else {
            this.form[this.data.key] = this.getNewlyAddedEntityData._id;
          }
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error at saving data",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>